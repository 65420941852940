<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"></span>
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table id="stickprov-table" role="table" aria-busy="false" aria-colcount="5" class="table b-table">
          <thead role="rowgroup" class="thead-light">
            <tr class="text-left" style=' border-radius: 1px; border-collapse: collapse; border-top-width: 1px;'>
              <th
                role="columnheader"
                scope="col"
                tabindex="0"
                aria-colindex="4"
                class="pl-7"
              >
                <div>
                  Namn
                </div>
              </th>
              <!-- <th style="min-width: 40px" class="pl-7">
                <span class="text-dark-100">Namn</span>
              </th> -->
              <th
                role="columnheader"
                scope="col"
                tabindex="0"
                aria-colindex="4"
              >
                <div>Pris</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class='pl-7'>Kostnad för SPAR</td>
              <td>
                <input type='number' v-model="local_billing.spar_budget">
              </td>
            </tr>
            <tr>
              <td class='pl-7'>Kostnad för SMS</td>
              <td>
                <input type='number' v-model="local_billing.sms_budget">
              </td>
            </tr>
            <tr>
              <td class='pl-7'>Systemkostnad</td>
              <td>{{ local_billing.system_cost }}</td>
            </tr>
            <tr>
              <td class='pl-7'>Betala Senast</td>
              <td>{{ local_billing.to_period }}</td>
            </tr>
            <tr>
              <td class='pl-7'>Faktura PDF</td>
              <td></td>
            </tr>
            <tr>
              <td class='pl-7'>Betala direkt</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <hr />
        <div class="d-flex justify-content-start">
          <b-button class="ml-3" type="button" variant="primary" @click="savePrice">Spara</b-button>
        </div>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>


<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'invoice-table',
  props: ['billing', 'currentCompanyId'],
  components: {},
  mixins: [ toasts ],
  methods: {
    savePrice() {
      const billing_id = this.local_billing.billing_id;
      const spar_budget  = this.local_billing.spar_budget;
      const sms_budget = this.local_billing.sms_budget;
      axios
        .put(`/billing/${billing_id}`, { billing_id, spar_budget, sms_budget })
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), 'Priset har sparats.');
          }

        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Det gick inte att spara priset.');
        });
    },
  },
  data() {
    return {
      local_billing: {},
      editing_sms_price: false,
      editing_spar_price: false,
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Position',
          field: 'position'
        }
      ],
      all_selected: false,
      list: []
    };
  },
  watch: {
    billing: {
      deep: true,
      handler(val) {
        this.local_billing = { ...val };
      }
    }
  },
  mounted() {
    if (this.billing) {
      this.local_billing = {...this.billing};
    }
  }
};
</script>
